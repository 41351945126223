hr.divider {
    max-width: 3.25rem;
    border-width: 0.2rem;
    border-color: #ffffff;
  }

hr.green {
  border-color: #00A859 !important;
}

.page-section {
  padding: 8rem 0;
}

.hovericon{
  color: #00A859;
  transition: color .40s ease;
}

.hovericon:hover{
  color: yellowgreen;
}