#mainNav {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    background-color: #fff;
    transition: background-color 0.2s ease;
  }
  #mainNav .navbar-brand {
    font-family: "Merriweather Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 700;
    color: #212529;
  }
  #mainNav .navbar-nav .nav-item .nav-link {
    color: #6c757d;
    font-family: "Merriweather Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 700;
    font-size: 0.9rem;
    padding: 0.75rem 0;
  }

  #mainNav .navbar-nav .nav-item .nav-link:hover, #mainNav .navbar-nav .nav-item .nav-link:active {
    color: #00A859;
  }
  #mainNav .navbar-nav .nav-item .nav-link.active {
    color: #00A859 !important;
  }
  @media (min-width: 992px) {
    #mainNav {
      box-shadow: none;
      background-color: transparent;
    }
    #mainNav .navbar-brand {
      color: rgba(255, 255, 255, 0.7);
      transition: color .5s ease;
    }
    #mainNav .navbar-brand:hover {
      color: #00A859;
    }
    #mainNav .navbar-nav .nav-item .nav-link {
      color: rgba(255, 255, 255, 0.7);
      padding: 0 1rem;
      transition: color .5s ease;
    }
    #mainNav .navbar-nav .nav-item .nav-link:hover {
      color: #00A859;
    }
    #mainNav .navbar-nav .nav-item:last-child .nav-link {
      padding-right: 0;
      
    }
    #mainNav.navbar-scrolled {
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
      background-color: #fff;
    }
    #mainNav.navbar-scrolled .navbar-brand {
      color: #212529;
    }
    #mainNav.navbar-scrolled .navbar-brand:hover {
      color: #00A859;
    }
    #mainNav.navbar-scrolled .navbar-nav .nav-item .nav-link {
      color: #212529;
      transition: color .5s ease;
    }
    #mainNav.navbar-scrolled .navbar-nav .nav-item .nav-link:hover {
      color:  #00A859;
    }
  }