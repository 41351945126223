header.masthead {
    padding-top: 10rem;
    padding-bottom: calc(10rem - 4.5rem);
    background: linear-gradient(to bottom, rgba(95, 100, 117, 0.8) 0%, rgba(95, 100, 117, 0.8) 100%), url("/assets/img/bg-masthead.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: cover;
  }
 
  header.masthead h1 {
    font-size: 2.25rem;
  }
  @media (min-width: 992px) {
    header.masthead {
      height: 100vh;
      min-height: 40rem;
      padding-top: 4.5rem;
      padding-bottom: 0;
    }
    header.masthead p {
      font-size: 1.15rem;
    }
    header.masthead h1 {
      font-size: 3rem;
    }
  }
  @media (min-width: 1200px) {
    header.masthead h1 {
      font-size: 3.5rem;
    }
  }

  .text-white-75 {
    color: rgba(255, 255, 255, 0.75);
  }

  .btn-xl {
    padding: 1.25rem 2.25rem;
    font-size: 0.85rem;
    font-weight: 700;
    text-transform: uppercase;
    border: none;
    border-radius: 10rem;
  }

  .btn-primary {
    color: #fff;
    background-color: #00A859;
    border-color: #00A859;
  }
  .btn-primary:hover {
    color: #fff;
    background-color: #00924e;
    border-color: #00924e;
  }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #00924e;
    border-color: #00924e;
    box-shadow: 0 0 0 0.2rem rgba(0, 212, 113, 0.9);
  }

  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #a7a7a7;
    border-color: #a7a7a7;
  }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #00924e;
    border-color: #00924e;
  }
  .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 212, 113, 0.9);
  }